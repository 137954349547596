var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Space Templates Restore","subtitle":"Browse and manage space templates!","badge":this.total}}),_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"}},[_c('filter-manager',{ref:"filterManager",attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}}),_c('v-sheet',{staticClass:"mx-1",attrs:{"height":"28","width":"1","color":"blue-grey lighten-4"}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","hide-details":"","clearable":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)])],1),_c('v-data-table',{staticClass:"elevation-2 templates-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.imageUrl",fn:function(ref){
    var item = ref.item;
return [_c('v-badge',{attrs:{"color":"secondary","bordered":"","left":"","offset-x":"20px","offset-y":"34px"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('i',{staticClass:"fal fa-code mb-0",staticStyle:{"font-size":"10px"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"space-img"},[(item.imageUrl != null && item.imageUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',attrs:{"src":item.imageUrl,"height":"100%","width":"100%"}})]):_c('i',{class:'fad fa-vector-square'})])])]}}],null,true)},[_c('span',[((item.imageUrl != null) & (item.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.imageUrl,"height":"250px","width":"250px","contain":""}}):_c('i',{class:'fad fa-vector-square',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])],1)]}},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [(item.client)?_c('v-tooltip',{attrs:{"top":"","nudge-top":"-16px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-2",on:{"click":function($event){$event.stopPropagation();return _vm.viewClient(item.client.id)}}},'div',attrs,false),on),[(item.client.logoUrl != null && item.client.logoUrl != '')?_c('v-img',{attrs:{"src":item.client.logoUrl,"max-height":"28","max-width":"54","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"28","max-width":"54","position":"left center","contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client.name))])]):_vm._e()]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.versionNumber",fn:function(ref){
    var item = ref.item;
return [(item.versionNumber != null)?_c('div',{staticClass:"mono-font fs-12px font-weight-bold"},[_vm._v(" "+_vm._s(item.versionNumber)+" ")]):_c('div',{staticClass:"mono-font fs-12px font-weight-bold text--secondary"},[_vm._v("1.0")])]}},{key:"item.createdBy",fn:function(ref){
    var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.createdBy,"icon":""}})]}},{key:"item.updatedBy",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy.firstName)+" "+_vm._s(item.createdBy.lastName))])]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"date-only":""}})]}},{key:"item.updateDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"date-only":""}})]}},{key:"item.equipmentCount",fn:function(ref){
    var item = ref.item;
return [_c('equipment-count',{attrs:{"count":item.equipmentCount}})]}},{key:"item.stats",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('count-tag',{attrs:{"count":item.countTemplateUsed,"icon":"fa-draw-square"},on:{"click":function($event){$event.stopPropagation();return _vm.displayStats(item)}}})],1)]}}],null,true)},[_c('span',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.countTemplateUsed))]),_vm._v(" "+_vm._s(item.countTemplateUsed == 1 ? "Connected Space" : "Connected Spaces")+" "),_c('br'),_c('hr',{staticClass:"my-1",staticStyle:{"opacity":"0.2"}}),_c('span',{staticStyle:{"font-size":"12px"}},[_c('i',{staticClass:"fad fa-swap-opacity fa-hand-pointer mr-1"}),_vm._v(" Click for more details! ")])])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","small":"","dark":""},on:{"click":function($event){return _vm.restore(item)}}},[_c('i',{staticClass:"fas fa-trash-arrow-up mr-2"}),_vm._v(" Restore ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"indigo","icon":"","dark":""},on:{"click":function($event){return _vm.displayActivityLogs(item)}}},[_c('i',{staticClass:"fas fa-history"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('space-template-activity-log',{ref:"spaceTemplateActivityLog"}),_c('edit-client',{ref:"editClient"}),_c('space-template-stats',{ref:"statesTemplate",attrs:{"template":_vm.selected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }