<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Space Templates Restore"
      subtitle="Browse and manage space templates!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col cols="12">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            hide-details
            clearable
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 templates-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.imageUrl`]="{ item }">
        <v-badge color="secondary" bordered left offset-x="20px" offset-y="34px">
          <template v-slot:badge>
            <i class="fal fa-code mb-0" style="font-size: 10px"></i>
          </template>
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="space-img">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.imageUrl != null && item.imageUrl != ''"
                  >
                    <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                  </div>
                  <i v-else :class="'fad fa-vector-square'"></i>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                class="img"
                :src="item.imageUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <i v-else :class="'fad fa-vector-square'" style="font-size: 32px; padding: 2rem"></i>
            </span>
          </v-tooltip>
        </v-badge>
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <v-tooltip v-if="item.client" top nudge-top="-16px">
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.client.id)">
              <v-img
                v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                :src="item.client.logoUrl"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="/img/DNA_Symbol.png"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
            </div>
          </template>
          <span>{{ item.client.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ item.name }}</strong>
        <!-- <p
          class="ma-0"
          v-if="item.description != null && $stripHtml(item.description.trim()) != ''"
        >
          {{ item.description | stripHtml }}
        </p> -->
      </template>

      <template v-slot:[`item.versionNumber`]="{ item }">
        <div v-if="item.versionNumber != null" class="mono-font fs-12px font-weight-bold">
          {{ item.versionNumber }}
        </div>
        <div v-else class="mono-font fs-12px font-weight-bold text--secondary">1.0</div>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.equipmentCount`]="{ item }">
        <equipment-count :count="item.equipmentCount" />
      </template>

      <template v-slot:[`item.stats`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-block" v-bind="attrs" v-on="on">
              <count-tag
                @click.stop="displayStats(item)"
                :count="item.countTemplateUsed"
                icon="fa-draw-square"
              ></count-tag>
            </div>
          </template>
          <span>
            <span class="mr-1">{{ item.countTemplateUsed }}</span>
            {{ item.countTemplateUsed == 1 ? "Connected Space" : "Connected Spaces" }}
            <br />
            <hr class="my-1" style="opacity: 0.2" />
            <span style="font-size: 12px">
              <i class="fad fa-swap-opacity fa-hand-pointer mr-1"></i> Click for more details!
            </span>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <span>{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="secondary" small dark @click="restore(item)">
          <i class="fas fa-trash-arrow-up mr-2"></i> Restore
        </v-btn>
        <v-btn color="indigo" class="ml-2" icon dark @click="displayActivityLogs(item)">
          <i class="fas fa-history"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <space-template-activity-log ref="spaceTemplateActivityLog"></space-template-activity-log>
    <edit-client ref="editClient"></edit-client>
    <space-template-stats ref="statesTemplate" :template="selected"></space-template-stats>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import SpaceTemplateActivityLog from "../components/activityLogs/SpaceTemplateActivityLog.vue";
import spaceTemplateService from "../services/spaceTemplates-service";
import SpaceTemplateStats from "../components/SpaceTemplateStats.vue";
import EditClient from "../../Companies/Clients/components/EditClient.vue";
import spaceTemplateHeader from "../config/tables/spaceTemplateRestore.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ClientFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    SpaceTemplateStats,
    EditClient,
    FilterManager,
    SpaceTemplateActivityLog,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      perms: perms,
      storageKey: "RestoreSpaceTemplates",
      openSpaceTemplateInEditMode: false,
      selectedFilters: [],
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        createdByIds: [],
        updatedByIds: [],
        ClientId: [],
      },
      lastSentOptions: null,
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: spaceTemplateHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    //this.$refs.mainSearch.focus();
    this.checkRouteQuery();
    this.checkForSingleTemplateRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var clientFilterSettings = new ClientFilterSettings();

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        clientFilterSettings,
      ];
    },
    displayActivityLogs(item) {
      setTimeout(() => {
        this.$refs.spaceTemplateActivityLog.open(item);
      });
    },
    viewClient(id) {
      this.$refs.editClient.open(id);
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      else this.options.search = null;
    },
    getEquipmentsCount(item) {
      if (item != null && item.areaGroups != null)
        return item.areaGroups.reduce((total, cur) => {
          // return total + cur.count + cur.count * this.getEquipmentAccessoriesCount(cur.equipment);
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    focus() {
      //this.$refs.mainSearch.focus();
    },
    getData() {
      this.$backToTop(0, document.querySelector(".templates-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      this.options.getDeletedOnly = true;
      spaceTemplateService
        .query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("getData Success > this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.spaceTemplatesTimerId == null) {
        this.spaceTemplatesTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.spaceTemplatesTimerId);

      // delay new call 400ms
      this.spaceTemplatesTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/restore-space-templates",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/restore-space-templates", replace: true });
    },
    restore(item) {
      this.$dialog
        .warning({
          text: `Are you sure you want to Restore this Space Template: <b>${item.name}</b>?`,
          title: `Restore Space Template?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return spaceTemplateService
                  .restore(item.id)
                  .then((resp) => {
                    this.$router.push({
                      name: `space-template`,
                      params: { id: item.id },
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    view(id) {
      setTimeout(() => {
        const path = `/restore-space-templates/${id}`;
        if (this.$route.path !== path)
          this.$router.push({
            path: path,
            query: { q: this.options.search },
            replace: true,
          });
      });
    },
    displayStats(item) {
      this.$log("displayStats", item);
      this.selected = JSON.parse(
        JSON.stringify({ ...this.entities.filter((e) => e.id == item.id)[0] })
      );
      setTimeout(() => {
        this.$refs.statesTemplate.open(item);
      });
    },
    checkForSingleTemplateRoute(route) {
      this.$log("checkForSingleTemplateRoute", route);
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openSpaceTemplate(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        var test_ToSend = this.cloneDeep(this.options);
        var test_LastSent =
          this.lastSentOptions == null
            ? this.cloneDeep(this.options)
            : this.cloneDeep(this.lastSentOptions);
        test_ToSend.page = null;
        test_LastSent.page = null;
        if (!this.isEqual(test_ToSend, test_LastSent) && this.options.page != 1) {
          this.options.page = 1;
        } else {
          this.getDataDebounced();
        }
        this.lastSentOptions = this.cloneDeep(this.options);
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();
        this.$log("SPACE TEMPLATES - oldRoute", oldRoute, "newRoute", newRoute);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.templates-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
